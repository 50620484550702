import { forwardRef } from "react";

const Section = forwardRef(function Section(props, ref) {
  const { id, className, children } = props;
  let finalClassName = "px-4 md:px-8";

  if (className) {
    finalClassName = finalClassName + " " + className;
  }

  return (
    <section ref={ref} id={id} className={finalClassName}>
      {children}
    </section>
  );
});

export default Section;
