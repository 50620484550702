import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../utilities/fetchUtilities";
import HomeInternals from "../components/HomeInternals";

function Home({ restBase }) {
  const navigate = useNavigate();
  const restPath = restBase + "pages/2?_embed";
  const restHomeMediaPath = restBase + "media?parent=2";
  //sidebarNav for logo/icons
  const restMediaSideBarPath = restBase + "pages/141?_embed";
  const restMediaPath = restBase + "media?parent=141";
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [restHomeMediaData, setHomeMediaData] = useState([]);
  const [isHomeMediaLoaded, setHomeMediaLoadStatus] = useState(false);
  const [restMediaData, setMediaData] = useState([]);
  const [isMediaLoaded, setMediaLoadStatus] = useState(false);
  const [restMediaSideBarData, setMediaSideBarData] = useState({});
  const [isMediaSideBarLoaded, setMediaSideBarLoadStatus] = useState(false);

  useEffect(() => {
    fetchData(restPath, setData, setLoadStatus, navigate);
    fetchData(
      restHomeMediaPath,
      setHomeMediaData,
      setHomeMediaLoadStatus,
      navigate
    );
    fetchData(restMediaPath, setMediaData, setMediaLoadStatus, navigate);
    fetchData(
      restMediaSideBarPath,
      setMediaSideBarData,
      setMediaSideBarLoadStatus,
      navigate
    );
  }, [
    restPath,
    restHomeMediaPath,
    restMediaPath,
    restMediaSideBarPath,
    navigate,
  ]);

  const pageFullyLoaded =
    isLoaded && isHomeMediaLoaded && isMediaLoaded && isMediaSideBarLoaded;

  return (
    <>
      {pageFullyLoaded ? (
        <HomeInternals
          restBase={restBase}
          restMediaSideBarData={restMediaSideBarData}
          restMediaData={restMediaData}
          restData={restData}
          restHomeMediaData={restHomeMediaData}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Home;
