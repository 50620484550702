// Accordion code modified from: https://cruip.com/building-a-simple-animated-accordion-component-with-tailwind-css/
import { useState } from "react";
import AccordionContent from "./AccordionContent";

export default function Accordion({
  restData,
  title,
  takeaway,
  restSingleMediaData,
  wireframesData,
}) {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="max-w-[1006px] mx-auto">
      <button
        className="flex justify-between items-center pt-3 pb-1 w-full border-b-2 border-thumbhover ml:mb-2"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
        aria-controls={`accordion-text-${title}`}
      >
        <span className="m-0 font-bold text-xl m:text-2xl mx:text-[1.7rem] m:pb-3">
          {title}
        </span>
        <svg
          className="shrink-0 ml-8 fill-btn"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`ttransform origin-center transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
        </svg>
      </button>
      {/* content */}
      <div
        id={`accordion-text-${title}`}
        role="region"
        aria-labelledby={`accordion-title-${title}`}
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden py-3 mx-2">
          {title === "Design" || title === "Technical Highlight" ? (
            <AccordionContent
              title={title}
              restData={restData}
              restSingleMediaData={restSingleMediaData}
              wireframesData={wireframesData}
            />
          ) : (
            <p className="text-left s:mb-10 ml:mt-6 mx:mt-10 mx:max-w-[910px] mx:mx-auto">
              {takeaway}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
