import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

export default function ToolkitTab({ restData }) {
  const data = [
    {
      label: restData.acf.tab_one,
      value: restData.acf.tab_one,
      desc: restData.acf.tab_one_text,
    },
    {
      label: restData.acf.tab_two,
      value: restData.acf.tab_two,
      desc: restData.acf.tab_two_text,
    },
    {
      label: restData.acf.tab_four,
      value: restData.acf.tab_four,
      desc: restData.acf.tab_four_text,
    },
  ];

  return (
    <Tabs value={data[0].value} className="max-w-lg">
      <TabsHeader className="py-3 bg-hr px-1 ml:px-3">
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            className="text-text font-bold font-biryani text-sm"
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="h-32">
        {data.map(({ value, desc }) => (
          <TabPanel
            key={value}
            value={value}
            className="text-left py-6 text-text font-cambay ml-1"
          >
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
