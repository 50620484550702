import { Link } from "react-router-dom";
import errorImg from "../assets/errorImg.webp";
import logo from "../assets/favicon.svg";

function ErrorPage() {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <header className="flex justify-center">
        <Link to="/">
          <img className="w-9 py-4" src={logo} alt="home" />
        </Link>
      </header>
      <main className="mx-3">
        <h1 className="my-10">Oops! Something went wrong...</h1>
        <img
          className="w-60 mx-auto my-10 md:w-96 ml:mt-16"
          src={errorImg}
          alt="time to have a coffee break"
        />
      </main>
      <footer className="bg-bg text-center py-10">
        &copy; 2024 Nathalie Chang | Front-End Web Developer
      </footer>
    </div>
  );
}

export default ErrorPage;
