import Accordion from "./elements/Accordion";

export default function Accordions({
  restData,
  restSingleMediaData,
  wireframesData,
}) {
  return (
    <div className="my-6">
      <Accordion
        title={restData.acf.accordion_title_design}
        restData={restData}
        restSingleMediaData={restSingleMediaData}
        wireframesData={wireframesData}
      />
      <Accordion
        title={restData.acf.accordion_title_technical_highlight}
        restData={restData}
        restSingleMediaData={restSingleMediaData}
      />
      <Accordion
        title={restData.acf["accordion-title-takeaway"]}
        takeaway={restData.acf.takeaway}
      />
    </div>
  );
}
