import { useState, useEffect } from "react";
import { fetchData, findDataObject } from "../utilities/fetchUtilities";
import Project from "./elements/Project";
import Loading from "./Loading";

export default function MoreProjects({ restBase, slug }) {
  const restPath = restBase + "pages/2?_embed";
  const restHomeMediaPath = restBase + "media?parent=2";
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [restHomeMediaData, setHomeMediaData] = useState([]);
  const [isHomeMediaLoaded, setHomeMediaLoadStatus] = useState(false);
  const currentPageSlug = slug;

  useEffect(() => {
    fetchData(restPath, setData, setLoadStatus);
    fetchData(restHomeMediaPath, setHomeMediaData, setHomeMediaLoadStatus);
  }, [restPath, restHomeMediaPath]);

  let projectList;
  // img id
  let imgID = "";
  let imgData = {};

  if (isLoaded && isHomeMediaLoaded) {
    projectList = restData.acf.imac_photo.map((e, i) => {
      imgID = e.more_project_section_image;
      imgData = findDataObject(restHomeMediaData, imgID);
      slug = e.individual_project_url;

      if (currentPageSlug === slug) {
        return undefined;
      } else {
        return (
          <Project
            key={`project-${i}`}
            link={imgData.guid.rendered}
            projectName={e.project_title_macbook}
            slug={slug}
          />
        );
      }
    });
  } else {
    projectList = <Loading />;
  }

  return projectList;
}
