export default function Typography({ restData }) {
  const typographyList = restData.acf.typography_info.map((e, i) => {
    const fontFamily = e.typography_name.trim();
    return (
      <div
        key={`font-${i}`}
        className="grid grid-cols-[1fr_1.5fr] items-center gap-5 mt-6 mb-6 mx-3 dt:mt-10"
      >
        <p className="sr-only">
          {e.where_applied} used {e.typography_name} font.
        </p>
        <p className="font-bold dt:text-2xl" aria-hidden="true">
          {e.where_applied}
        </p>
        <div aria-hidden="true">
          <div className="mb-1">
            <span
              className={`text-left mr-4 text-xl font-bold dt:text-2xl`}
              style={{ fontFamily: fontFamily }}
            >
              Aa
            </span>
            <span
              className={`text-left font-bold text-lg dt:text-2xl`}
              style={{ fontFamily: fontFamily }}
            >
              {e.typography_name}
            </span>
          </div>
          <p
            className={`text-sm text-left leading-4 tracking-typography mb-2 dt:text-xl`}
            style={{ fontFamily: fontFamily }}
          >
            {restData.acf.typography_text.toUpperCase()}
          </p>
          <p
            className={`text-sm text-left leading-4 tracking-typography dt:text-xl`}
            style={{ fontFamily: fontFamily }}
          >
            {restData.acf.typography_text}
          </p>
          <p
            className={`text-left dt:text-xl dt:mt-2`}
            style={{ fontFamily: fontFamily }}
          >
            {restData.acf.typography_number}
          </p>
        </div>
      </div>
    );
  });

  return typographyList;
}
