import { Link, NavLink } from "react-router-dom";
//Type animation modified from: https://www.npmjs.com/package/react-type-animation
import { TypeAnimation } from "react-type-animation";
import Section from "../components/elements/Section";
import { findDataObject } from "../utilities/fetchUtilities";
import ToolkitTab from "../components/ToolkitTab";
import ScrollAnimation from "../components/ScrollAnimation";
import Thumbnails from "../components/Thumbnails";
import Footer from "../components/Footer";
import ScrollToAnchor from "../components/ScrollToAnchor";
import SideNav from "../components/SideNav";
import { useIsVisible } from "../utilities/visibility";
import FeaturedWorkList from "../components/elements/FeaturedWorkList";

export default function HomeInternals({
  restBase,
  restMediaSideBarData,
  restMediaData,
  restData,
  restHomeMediaData,
}) {
  const { ref, isIntersecting: isVisible } = useIsVisible();
  const logoID = restMediaSideBarData.acf.site_logo;
  const headshotID = restData.acf.headshot;
  const artID = restData.acf.art_therapy_background_image;
  const medID = restData.acf.heart_mandala;

  const logoData = findDataObject(restMediaData, logoID);
  const headshotData = findDataObject(restHomeMediaData, headshotID);
  const artData = findDataObject(restHomeMediaData, artID);
  const medData = findDataObject(restHomeMediaData, medID);
  return (
    <>
      <ScrollToAnchor />
      <Link to="#featured-work" className="sr-only">
        Skip to content
      </Link>
      <SideNav
        restBase={restBase}
        worksSectionVisible={isVisible}
        restMediaSideBarData={restMediaSideBarData}
      />
      <main id={`post-${restData.id}`} className="md:ml-14 dt:ml-20 dx:ml-24">
        <Section
          id="home"
          className="relative pb-64 overflow-hidden md:pb-48 mx:pb-56 dt:pb-80"
        >
          {/* bg animation */}
          <div aria-hidden="true">
            <div className="absolute z-10 bg-[#F4B0DD] h-[210px] w-[210px] rounded-full top-44 -right-4 filter blur-2xl opacity-55 animate-blob md:left-72 md:right-auto md:top-36 mx:left-96 mx:top-48 dx:left-[580px] dx:w-280 dx:h-280 dx:top-52" />
            <div className="absolute z-10 bg-[#f4edb1] h-[170px] w-[170px] rounded-full top-52 -left-3 filter blur-2xl opacity-55 animate-blob animation-delay-4000 md:left-14 md:right-auto mx:left-32 mx:top-72 dx:left-72 dx:w-64 dx:h-64" />
            <div className="absolute z-10 bg-[#EBA68F] h-[100px] w-[100px] rounded-full top-96 right-4 filter blur-2xl opacity-50 animate-blob animation-delay-2000 md:left-52 md:right-auto mx:left-80 mx:top-[500px] dx:left-[590px] dx:top-[520px]" />
          </div>
          {/* logo */}
          <NavLink to="/" className="md:collapse mb-8">
            <img
              src={logoData.guid.rendered}
              alt="Site logo"
              className="mobile-logo mb-8"
              width="32"
              height="36.234"
            />
          </NavLink>
          <div>
            {/* name and titles */}
            <div className="absolute z-40 entry-content left-0 right-0 top-16 md:top-20 md:left-16 md:right-auto ml:left-36 mx:left-72 mx:top-36 dx:left-96 dx:top-40">
              <h1 className="mb-14 mt-52 md:text-left md:mt-60 md:mb-7 dx:mb-10">
                {restData.title.rendered}
              </h1>
              <h2
                className="mb-3 md:text-left"
                aria-label="front-end web developer, UX/UI designer"
              >
                {restData.acf["front-end_web_developer"]}
              </h2>
              <div className="text-center md:text-left" aria-hidden="true">
                {/* Type animation */}
                <TypeAnimation
                  sequence={["Web Developer", 1500, "UI/UX Designer", 1500]}
                  speed={1}
                  repeat={Infinity}
                  className="text-xl font-bold dx:text-3xl"
                />
              </div>
            </div>
            {/* big screen nav 768px*/}
            <div
              role="navigation"
              className="md:flex justify-around md:mt-36 ml:justify-center dt:mr-20 dx:mr-0 dx:mt-48"
            >
              <div className="md:w-2/5 ml:w-1/2 dx:w-2/6" />
              <nav className="collapse md:visible z-50 order-last">
                <ul>
                  <li>
                    <Link
                      to="/#featured-work"
                      aria-label="see my featured work"
                    >
                      <h2 className="home-hero">
                        {restData.acf.hero_nav_works}
                      </h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#about-me">
                      <h2 className="home-hero my-24">
                        {restData.acf.hero_nav_about}
                      </h2>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#contact">
                      <h2 className="home-hero">
                        {restData.acf.hero_nav_contact}
                      </h2>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </Section>
        {/* Featured Work */}
        <div ref={ref} className="md:mt-32">
          <Section id="featured-work" className="bg-bg pt-16 pb-8">
            <h2 className="mb-16 md:mt-10 md:mb-18 mx:mb-24">
              {restData.acf.featured_work_title}
            </h2>
            <ScrollAnimation>
              <ul className="md:flex md:gap-10 md:justify-around max-w-5xl mx-auto">
                <FeaturedWorkList
                  restData={restData}
                  restHomeMediaData={restHomeMediaData}
                />
              </ul>
            </ScrollAnimation>
          </Section>
          {/* About-headshot */}
          <Section
            id="about-me"
            className="about-one pt-16 max-w-[1080px] mx-auto md:flex md:pt-14 md:gap-10 md:my-16 ml:gap-16 ml:my-28 mx:my-36 m:gap-28 dt:my-48"
          >
            <ScrollAnimation>
              <img
                src={headshotData.guid.rendered}
                alt="about me"
                className="rounded-b-xl rounded-t-full w-full max-w-xs mx-auto flex-shrink-0 md:w-fit md:pt-0"
                loading="lazy"
                width="320"
                height="479.750"
              />
            </ScrollAnimation>
            <div className="mt-10 md:mt-0" role="article">
              <h2 className="mt-10 text-left md:mt-0">
                {
                  restData.acf
                    .design_and_develop_simple_and_positive_experiences_for_users
                }
              </h2>
              <h3 className="text-left mt-14 mb-4">
                {restData.acf["01_detailed-oriented_-_user-centred"]}
              </h3>
              <p className="text-left mb-10">
                {restData.acf["focused_on_details_and_user-centric_principles"]}
              </p>
              <h3 className="text-left">{restData.acf["02_my_toolkit"]}</h3>
              <ul className="sr-only">
                <li>
                  Front-End: HTML/CSS, JavaScript, React, Sass, Tailwind,
                  WordPress, WooCommerce, Shopify.
                </li>
                <li>Back-End: PHP, SQL.</li>
                <li>Design: Illustrator, Photoshop, Figma, Canva.</li>
              </ul>
              {/* Toolkit Tab */}
              <div className="pb-4 pt-6" aria-hidden="true">
                <ToolkitTab restData={restData} />
              </div>
            </div>
          </Section>
          <section
            className="about-art mb-20 mt-10 flex items-center justify-center min-h-[900px] w-full bg-fixed bg-center bg-no-repeat bg-cover dx:h-[1100px]"
            style={{
              backgroundImage: `url(${artData.guid.rendered})`,
            }}
          >
            <ScrollAnimation>
              <div
                className="bg-blanc bg-opacity-90 rounded-xl py-5 px-5 mx-6 my-auto max-w-md s:mx-auto s:p-10 mx:max-w-[516px] mx:py-12"
                role="article"
              >
                <h2 className="mb-10 italic dx:mb-12">
                  {
                    restData.acf
                      .bringing_a_unique_perspective_shaped_by_my_background_in_counselling_art_therapy
                  }
                </h2>
                <p className="text-left">{restData.acf.at_text}</p>
              </div>
            </ScrollAnimation>
          </section>
          <section className="about-med flex flex-col max-w-[1080px] mx-auto items-center md:flex-row md:mb-10 md:gap-14 ml:my-28 m:gap-20 justify-end mx:mb-40 dt:mt-40">
            <ScrollAnimation>
              <img
                src={medData.guid.rendered}
                alt="a cup of tea with some candles"
                className="mb-10 max-w-xs mx-auto rounded-sm md:mb-0"
                loading="lazy"
                width="320"
                height="480"
              />
            </ScrollAnimation>
            <div
              className="pt-10 pb-14 px-4 md:px-8 -order-1 md:self-end md:pb-0 md:pr-0 m:px-0 m:max-w-lg"
              role="article"
            >
              <h2 className="mb-10 italic md:text-left">
                {restData.acf.last_paragraph_heading}
              </h2>
              <p className="text-left">{restData.acf.last_paragraph_text}</p>
            </div>
          </section>
        </div>
      </main>
      <Footer restBase={restBase} />
      <Thumbnails restBase={restBase} />
    </>
  );
}
