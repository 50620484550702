import { useState, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Section from "../components/elements/Section";
import Accordions from "../components/Accordions";
import MoreProjects from "../components/MoreProjects";
import Thumbnails from "../components/Thumbnails";
import Footer from "../components/Footer";
import ScrollToAnchor from "../components/ScrollToAnchor";
import SideNav from "../components/SideNav";
import { fetchData, findDataObject } from "../utilities/fetchUtilities";

function SingleWork({ restBase }) {
  const navigate = useNavigate();
  const { slug } = useParams();

  let num = "116"; //florist
  if (slug === "movie") {
    num = "38";
  } else if (slug === "my-portfolio") {
    num = "83";
  }

  const restPath = restBase + "portfolio-work?_embed&slug=" + slug;
  const restSingleMediaPath = restBase + "media?parent=" + num;
  //sidebarNav for logo/icons
  const restMediaSideBarPath = restBase + "pages/141?_embed";
  const restMediaPath = restBase + "media?parent=141";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);
  const [restSingleMediaData, setSingleMediaData] = useState([]);
  const [isSingleMediaLoaded, setSingleMediaLoadStatus] = useState(false);
  const [restMediaSideBarData, setMediaSideBarData] = useState({});
  const [isMediaSideBarLoaded, setMediaSideBarLoadStatus] = useState(false);
  const [restMediaData, setMediaData] = useState([]);
  const [isMediaLoaded, setMediaLoadStatus] = useState(false);

  useEffect(() => {
    fetchData(restPath, setData, setLoadStatus, navigate);
    fetchData(restMediaPath, setMediaData, setMediaLoadStatus, navigate);
    fetchData(
      restSingleMediaPath,
      setSingleMediaData,
      setSingleMediaLoadStatus,
      navigate
    );
    fetchData(
      restMediaSideBarPath,
      setMediaSideBarData,
      setMediaSideBarLoadStatus,
      navigate
    );
  }, [
    restPath,
    restMediaPath,
    restSingleMediaPath,
    restMediaSideBarPath,
    navigate,
  ]);

  useEffect(() => {
    if (
      slug !== "movie" &&
      slug !== "my-portfolio" &&
      slug !== "florist" &&
      slug !== ""
    ) {
      navigate("404");
    }
  }, [slug]);

  //loop technology list
  let techList = "";
  if (isLoaded) {
    techList = restData[0].acf.technologies_list.map((element, i) => (
      <li className="bg-btn py-2 px-3 rounded-lg text-blanc" key={`tech-${i}`}>
        {element.tech_item}
      </li>
    ));
  }

  // img id
  let logoID = "";
  let macID = "";
  let wireframesID = "";

  let logoData = {};
  let macData = {};
  let wireframesData = {};

  if (
    isLoaded &&
    isMediaLoaded &&
    isSingleMediaLoaded &&
    isMediaSideBarLoaded
  ) {
    logoID = restMediaSideBarData.acf.site_logo;
    macID = restData[0].acf.macbook;
    wireframesID = restData[0].acf.wireframe_image;

    logoData = findDataObject(restMediaData, logoID);
    macData = findDataObject(restSingleMediaData, macID);
    wireframesData = findDataObject(restSingleMediaData, wireframesID);
  } else {
  }

  return (
    <>
      {isLoaded &&
      isMediaLoaded &&
      isSingleMediaLoaded &&
      isMediaSideBarLoaded &&
      restData[0] ? (
        <>
          <ScrollToAnchor />
          <Link to="#project" className="sr-only">
            Skip to content
          </Link>
          <SideNav
            slug={slug}
            restBase={restBase}
            restMediaSideBarData={restMediaSideBarData}
          />
          <main
            id={`post-${restData[0].id}`}
            className="md:ml-14 dt:ml-20 dx:ml-24"
          >
            <Section className="bg-bg pb-16 mx-auto">
              <NavLink
                to="/"
                className="md:collapse"
                aria-label="navigate to homepage"
              >
                {/* logo */}
                <img
                  src={logoData.guid.rendered}
                  alt="Site logo"
                  className="mobile-logo mb-10"
                  width="32"
                  height="36.234"
                />
              </NavLink>
              {/* Macbook */}
              <div
                id="project"
                className="mt-14 ml:flex ml:gap-10 ml:items-start max-w-[1100px] mx:mx-auto"
              >
                <div className="mb-14 ml:w-11/12">
                  <img
                    className="mx-auto w-11/12 md:mt-16 md:w-[350px] md:h-auto ml:w-full"
                    src={macData.guid.rendered}
                    alt="website"
                    width="523.828"
                    height="300.297"
                  />
                </div>
                <div className="mx-auto w-4/5 ml:ml-0 ml:shrink">
                  <h1 className="s:mt-16 ml:text-left ml:mb-6 mx:mb-10 mx:mt-24">
                    {restData[0].title.rendered}
                  </h1>
                  <p className="text-left s:mb-16 ml:mb-8">
                    {restData[0].acf.work_overview}
                  </p>
                </div>
              </div>
              <nav className="flex justify-center gap-10 my-10">
                {/* Buttons */}
                <Link
                  to={
                    restData[0].acf.live_site_link !== null
                      ? restData[0].acf.live_site_link.url
                      : "https://nathalie.somaticarttherapy.co/"
                  }
                  target="_blank"
                  className="btn"
                >
                  {restData[0].acf.btn_live_site}
                </Link>
                <Link
                  to={
                    restData[0].acf.github_link !== null
                      ? restData[0].acf.github_link.url
                      : "https://github.com/Nathalie-C"
                  }
                  target="_blank"
                  className="btn"
                >
                  {restData[0].acf.btn_github}
                </Link>
              </nav>
            </Section>
            {/* Technologies */}
            <Section className="work-technologies my-16 s:mb-32 s:mt-24 m:mt-30 m:mb-40">
              <h2 className="mb-10">{restData[0].acf.technologies}</h2>
              <ul className="tech-list flex gap-3 justify-center flex-wrap">
                {techList}
              </ul>
            </Section>
            {/* accordions */}
            <Section className="work-accordions mb-10 s:mx-10">
              <Accordions
                restData={restData[0]}
                restSingleMediaData={restSingleMediaData}
                wireframesData={wireframesData}
              />
            </Section>
            {/* more projects */}
            <section className="work-more-projects mb-3 mt-20 s:my-24 md:my-32 ml:mb-24 m:mt-40 mx:mt-52 mx:mb-32">
              <h2 className="mb-5 s:mb-11 md:mb-16">
                {restData[0].acf.more_projects}
              </h2>
              <ul className="flex gap-9 overflow-x-auto md:gap-7 ml:pb-6 m:justify-center m:gap-11 dx:gap-14 mx-auto">
                <MoreProjects restBase={restBase} slug={slug} />
              </ul>
            </section>
          </main>
          <Footer restBase={restBase} />
          <Thumbnails restBase={restBase} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SingleWork;
