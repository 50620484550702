export const fetchData = async (restPath, setData, setLoadStatus, navigate) => {
  setLoadStatus(false);
  try {
    const response = await fetch(restPath);

    if (response.ok) {
      const data = await response.json();

      setData(data);
      setLoadStatus(true);
    } else {
      setLoadStatus(false);
      // Custom message for failed HTTP codes
      if (response.status === 404) throw new Error("404, Not found");
      if (response.status === 500)
        throw new Error("500, internal server error");
      // For any other server error
      throw new Error(response.status);
    }
  } catch (error) {
    navigate("404");
    // Output e.g.: "Fetch Error: 404, Not found"
  }
};

export const findDataObject = function (data, ID) {
  return data.find((data) => data.id === ID);
};
