import { NavLink } from "react-router-dom";

export default function Project({ link, projectName, slug }) {
  return (
    <li className="drop-shadow-xl my-6 w-64 flex-shrink-0 s:w-80 dx:w-96">
      <NavLink to={`/${slug}`}>
        <img
          className="rounded-t-lg h-auto w-full "
          src={link}
          alt="project"
          loading="lazy"
          width="320"
          height="165.266"
        />
        <div className="bg-blanc rounded-b-lg px-2">
          <h5 className="pt-4 hover:text-btn active:text-btn visited:text-text focus:text-text dx:text-2xl dx:pt-10 dx:pb-6">
            {projectName}
          </h5>
          <p
            className="pb-4 hover:text-btn active:text-btn visited:text-text focus:text-text inline-block w-full text-center dx:pb-3"
            aria-hidden="true"
          >
            {`View More -->`}
          </p>
        </div>
      </NavLink>
    </li>
  );
}
