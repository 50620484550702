import { Link } from "react-router-dom";
import ScrollAnimation from "../ScrollAnimation";

export default function TechHighlight({ restSingleMediaData, restData }) {
  const techList = restData.acf.feature.map((e, i) => {
    const imgID = e.feature_image;

    const mediaObjectWithId = restSingleMediaData.find(
      (object) => object.id === imgID
    );

    return (
      <section key={i} className="mb-16 mt-5 s:mb-18 md:mt-6 mx:mt-10 dt:mb-28">
        <ScrollAnimation>
          <div className="ml:flex ml:gap-10 ml:items-start">
            <div className="ml:w-2/5">
              <img
                className="rounded-lg w-11/12 mx-auto shadow-[0_5px_15px_0_rgba(0,0,0,0.15)]"
                src={mediaObjectWithId.guid.rendered}
                alt={`feature ${i + 1}`}
                loading="lazy"
              />
            </div>
            <div className="ml:w-3/5">
              <h4 className="w-11/12 text-left mt-10 mb-3 s:mt-10 s:mb-6 ml:mt-0 ml:w-full">
                {e.feature_name}
              </h4>
              <p className="w-11/12 text-left mb-6 ml:w-full">
                {e.feature_description}
              </p>
              {e.feature_code !== "" ? (
                <Link
                  to={e.feature_code.url}
                  target="_blank"
                  className="text-left hover:text-btn active:text-btn visited:text-text focus:text-text"
                >{`View Code -->`}</Link>
              ) : (
                <div />
              )}
            </div>
          </div>
        </ScrollAnimation>
      </section>
    );
  });

  return techList;
}
