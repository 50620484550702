import { NavLink } from "react-router-dom";

function Thumbnail({ text, objectKeyNum, img, linkTo, creditPage }) {
  if (objectKeyNum === 0 && window.location.pathname !== creditPage) {
    text = "To Top";
  }

  return (
    <li className="hover:bg-thumbhover h-58 w-72 my-1.5 rounded-thumbnav">
      <NavLink to={linkTo}>
        <img
          src={img}
          alt="thumbnail icon"
          className="h-7 w-auto mt-1.5 mx-auto hover:drop-shadow-xl"
          width="30"
          height="28"
        />
        <p className="text-xs text-blanc mt-1.5 text-center hover:drop-shadow-xl">
          {text}
        </p>
      </NavLink>
    </li>
  );
}

export default Thumbnail;
