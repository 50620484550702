import { useState } from "react";
import { Tooltip } from "@material-tailwind/react";

const copyMsg = {
  defaultMsg: "Click to Copy Email",
  copied: "Email Copied Successfully!",
};

export default function CopyEmail({ restMediaData, restData, sideNav }) {
  const email = restData.acf.my_email_address;
  const [msg, setMsg] = useState(copyMsg.defaultMsg);
  const canCopy = window?.navigator?.clipboard?.writeText !== undefined;

  function copy() {
    if (canCopy) {
      window.navigator.clipboard.writeText(email);
      setMsg(copyMsg.copied);
    } else {
      setMsg(email);
    }
  }

  function resetMsg() {
    if (canCopy) {
      setMsg(copyMsg.defaultMsg);
    }
  }

  let interactive = false;
  if (msg === email) {
    interactive = true;
  }

  let classStyle = "w-11 h-11";
  let placementPosition = "top-start";

  if (sideNav === true) {
    classStyle = "sideNav-icon";
    placementPosition = "right";
  }

  return (
    <>
      <Tooltip
        content={msg}
        placement={placementPosition}
        className="font-cambay bg-transparent text-text"
        interactive={interactive}
      >
        <img
          src={restMediaData[2].guid.rendered}
          alt={`Email: ${email}`}
          className={classStyle}
          onClick={copy}
          onMouseOut={resetMsg}
        />
      </Tooltip>
    </>
  );
}
