import { findDataObject } from "../../utilities/fetchUtilities";
import FeaturedWork from "./FeaturedWork";

function FeaturedWorkList({ restData, restHomeMediaData }) {
  const featuredList = restData.acf.imac_photo.map((e, i) => {
    let imgID = "";
    let imgData = {};

    imgID = e.single_project_macbook;
    imgData = findDataObject(restHomeMediaData, imgID);

    return (
      <FeaturedWork
        key={`project-${i}`}
        link={imgData.guid.rendered}
        title={e.project_title_macbook}
        url={e.individual_project_url}
      />
    );
  });
  return featuredList;
}

export default FeaturedWorkList;
