import Typography from "./Typography";
import TechHighlight from "./TechHighlight";
import frame from "../../assets/frame.webp";
import { useState } from "react";

export default function AccordionContent({
  title,
  restData,
  restSingleMediaData,
  wireframesData,
}) {
  const [scrollHidden, setScrollHidden] = useState(false);
  const [previousTimeOutId, setPreviousTimeOutId] = useState(undefined);
  const [scrollWillBeHiddenSoon, setScrollWillBeHiddenSoon] = useState(false);

  const paletteList = restData.acf.palette.map((e, i) => {
    const hexCode = e.color.toLowerCase().trim();
    return (
      <li key={i}>
        <div
          className={`border-2 border-b-0 border-slate-200 h-10 rounded-t-lg  m:h-12`}
          style={{ backgroundColor: hexCode }}
        />
        <p className="border-2 border-slate-200 rounded-b-sm px-4 py-1 dt:text-xl">
          {e.color}
        </p>
      </li>
    );
  });

  function scrollDetect() {
    if (!scrollHidden && !scrollWillBeHiddenSoon) {
      setTimeout(() => {
        setScrollHidden(true);
      }, 300);
      setScrollWillBeHiddenSoon(true);
    }

    if (previousTimeOutId !== undefined) {
      clearTimeout(previousTimeOutId);
      setPreviousTimeOutId(undefined);
    }

    const timeOutShowId = setTimeout(() => {
      setScrollHidden(false);
      setScrollWillBeHiddenSoon(false);
    }, 1500);

    setPreviousTimeOutId(timeOutShowId);
  }

  return title === "Design" ? (
    // Design section
    <div className="mb-10 mt-5 mx:mt-10 mx:max-w-[810px] mx:mx-auto">
      <section>
        <h4 className="text-left m:ml-5">{restData.acf.color_palette}</h4>
        <ul className="flex flex-wrap justify-center gap-6 mt-6 mb-14 m:gap-8 m:w-[440px] m:mx-auto">
          {paletteList}
        </ul>
      </section>
      <section className="mb-10 mx:my-20">
        <h4 className="text-left mb-5 m:ml-5">{restData.acf.typography}</h4>
        {/* Typography List*/}
        <Typography restData={restData} />
      </section>
      <section className="relative">
        {/* wireframe */}
        <h4 className="text-left mb-10 m:ml-5">{restData.acf.wireframes}</h4>
        <div
          className={`absolute top-[5rem] right-[1rem] ${
            scrollHidden ? "opacity-0 duration-700" : "opacity-1 duration-700"
          }`}
        >
          <div className="relative text-center ml-10 " aria-hidden="true">
            <div className="absolute top-[0.25rem] left-[1.05rem] w-1.5 h-1.5 bg-btn rounded-full animate-scroll" />
            <img
              src={frame}
              alt="frame of scroll down icon"
              className="w-7 ml-1.5"
              loading="lazy"
            />
          </div>
        </div>
        <div className="overflow-y-auto h-96" onScroll={scrollDetect}>
          <img
            src={wireframesData.guid.rendered}
            alt="wireframes created using Figma"
            loading="lazy"
          />
        </div>
      </section>
    </div>
  ) : (
    // technical highlight section
    <TechHighlight
      restData={restData}
      restSingleMediaData={restSingleMediaData}
      className="mt-5"
    />
  );
}
