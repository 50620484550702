import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import Thumbnail from "./elements/Thumbnail";

function Thumbnails({ restBase }) {
  const restPath = restBase + "pages/2?_embed";
  const restMediaPath = restBase + "media?parent=148";
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [restMediaData, setMediaData] = useState([]);
  const [isMediaLoaded, setMediaLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();

    const fetchMediaData = async () => {
      const response = await fetch(restMediaPath);
      if (response.ok) {
        const data = await response.json();

        setMediaData(data);
        setMediaLoadStatus(true);
      } else {
        setMediaLoadStatus(false);
      }
    };
    fetchMediaData();
  }, [restPath, restMediaPath]);

  const param = useParams();
  const objectKeyNum = Object.keys(param).length;
  const creditPage = "/credits";

  return (
    <>
      {isLoaded && isMediaLoaded && param ? (
        <nav
          id={`post-${restMediaData.id}`}
          className="bg-thumnail bg-opacity-90 backdrop-blur-md h-70 rounded-t-thumbnav fixed bottom-0 left-0 right-0 z-50 md:collapse"
        >
          <ul className="flex gap-1 justify-around mx-4">
            <Thumbnail
              img={restMediaData[3].guid.rendered}
              text={restData.acf.hero_nav_home}
              linkTo={"/#home"}
              objectKeyNum={objectKeyNum}
              creditPage={creditPage}
            />
            <Thumbnail
              img={restMediaData[1].guid.rendered}
              text={restData.acf.hero_nav_works}
              linkTo={"/#featured-work"}
            />
            <Thumbnail
              img={restMediaData[0].guid.rendered}
              text={restData.acf.hero_nav_about}
              linkTo={"/#about-me"}
            />
            <Thumbnail
              img={restMediaData[2].guid.rendered}
              text={restData.acf.hero_nav_contact}
              linkTo={
                objectKeyNum !== 0 || window.location.pathname === creditPage
                  ? "#contact"
                  : "/#contact"
              }
            />
          </ul>
        </nav>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Thumbnails;
