import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "../components/Loading";
import Thumbnails from "../components/Thumbnails";
import Footer from "../components/Footer";
import SideNav from "../components/SideNav";
import { fetchData } from "../utilities/fetchUtilities";
import ScrollToAnchor from "../components/ScrollToAnchor";

function Credits({ restBase }) {
  const navigate = useNavigate();
  const restMediaSideBarPath = restBase + "pages/141?_embed";
  const restPath = restBase + "pages/324?_embed";
  const [restMediaSideBarData, setMediaSideBarData] = useState({});
  const [isMediaSideBarLoaded, setMediaSideBarLoadStatus] = useState(false);
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);

  useEffect(() => {
    fetchData(
      restMediaSideBarPath,
      setMediaSideBarData,
      setMediaSideBarLoadStatus,
      navigate
    );
    fetchData(restPath, setData, setLoadStatus, navigate);
  }, [restMediaSideBarPath, restPath, navigate]);

  //loop img list
  let imgList = "";
  if (isLoaded) {
    imgList = restData.acf.credit_info.map((e, i) => (
      <li className="py-2 px-3 md:p-0 md:mb-4" key={`img-${i}`}>
        <p className="text-left">
          <span>{e.credit_name}: </span>
          <Link
            to={e.source_link.url ? e.source_link.url : "#"}
            target="_blank"
            className="underline hover:text-btn"
          >
            {e.credit_link}
          </Link>
        </p>
      </li>
    ));
  }

  //loop icon list
  let iconList = "";
  if (isLoaded) {
    iconList = restData.acf.credit_info_icon.map((e, i) => (
      <li className="py-2 px-3 md:p-0 md:mb-4" key={`img-${i}`}>
        <p className="text-left">
          {e.credit_name_icon !== "" ? (
            <span>{e.credit_name_icon}: </span>
          ) : (
            <span></span>
          )}
          <Link
            to={e.icon_link.url ? e.icon_link.url : "#"}
            target="_blank"
            className="underline hover:text-btn"
          >
            {e.credit_link_icon}
          </Link>
        </p>
      </li>
    ));
  }

  return (
    <>
      {isMediaSideBarLoaded && isLoaded ? (
        <>
          <ScrollToAnchor />
          <Link to="#featured-work" className="sr-only">
            Skip to content
          </Link>
          <SideNav
            restBase={restBase}
            restMediaSideBarData={restMediaSideBarData}
            credit={"credit"}
          />
          <div className="flex flex-col justify-between">
            <header>
              <h1 className="pt-11 mb-12 md:pb-16 md:ml-14 dt:ml-20 dx:ml-24">
                Credits
              </h1>
            </header>
            <main className="mx-auto md:flex md:justify-center md:gap-16 md:pb-32 md:mr-0 md:ml-14 dt:ml-20 dx:ml-24">
              <section>
                <h2 className="mb-10 text-left">
                  {restData.acf.credit_title_image}
                </h2>
                <ul>{imgList}</ul>
              </section>
              <section className="">
                <h2 className="mt-11 mb-10 text-left md:mt-0">
                  {restData.acf.credit_title_icon}
                </h2>
                <ul>{iconList}</ul>
              </section>
            </main>
            <Footer restBase={restBase} />
          </div>
          <Thumbnails restBase={restBase} credit={"credit"} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Credits;
