import { useState, useEffect } from "react";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import CopyEmail from "./CopyEmail";

function Footer({ restBase }) {
  const restPath = restBase + "pages/143?_embed";
  const restMediaPath = restBase + "media?parent=143";
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [restMediaData, setMediaData] = useState([]);
  const [isMediaLoaded, setMediaLoadStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(restPath);
      if (response.ok) {
        const data = await response.json();

        setData(data);
        setLoadStatus(true);
      } else {
        setLoadStatus(false);
      }
    };
    fetchData();

    const fetchMediaData = async () => {
      const response = await fetch(restMediaPath);
      if (response.ok) {
        const data = await response.json();

        setMediaData(data);
        setMediaLoadStatus(true);
      } else {
        setMediaLoadStatus(false);
      }
    };
    fetchMediaData();
  }, [restPath, restMediaPath]);

  return (
    <>
      {isLoaded && isMediaLoaded ? (
        <footer
          id="contact"
          className="pb-20 pt-10 md:pb-6 dx:pt-20 md:ml-14 dt:ml-20 dx:ml-24"
        >
          <div className="mt-10 mx-auto max-w-[1080px]">
            <h2 className="my-10">{restData.acf.simply_connect}</h2>
            <nav className="mt-10 mb-14">
              <ul className="flex justify-center gap-4">
                <li>
                  <Link to={restData.acf.linkedin_copy} target="_blank">
                    <img
                      src={restMediaData[0].guid.rendered}
                      alt="LinkedIn"
                      title="LinkedIn"
                      className="w-11 h-11"
                    />
                  </Link>
                </li>
                <li className="s:mx-4">
                  <Link to={restData.acf.github_link} target="_blank">
                    <img
                      src={restMediaData[1].guid.rendered}
                      alt="GitHub"
                      title="GitHub"
                      className="w-11 h-11"
                    />
                  </Link>
                </li>
                <li>
                  <CopyEmail
                    restMediaData={restMediaData}
                    restData={restData}
                  />
                </li>
              </ul>
            </nav>
            <div className="w-10/12 mx-auto text-center">
              <span>
                {`${restData.acf.copyright} | `}
                <Link to="/credits" className="underline hover:text-btn">
                  Credits
                </Link>
              </span>
            </div>
          </div>
        </footer>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Footer;
