// appear on scroll animation is modified from: https://medium.com/@jacobvejlinjensen/how-to-create-a-smooth-appear-on-scroll-transition-with-tailwind-css-and-react-82f2a32ab295
import { useIsVisible } from "../utilities/visibility";

export default function ScrollAnimation({ children }) {
  const { ref, isIntersecting: isVisible } = useIsVisible();

  return (
    <>
      <div
        ref={ref}
        className={`transition-opacity ease-in duration-1000 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        {children}
      </div>
    </>
  );
}
